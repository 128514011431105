<template>
  <div class="content">
    <div id="header1">
        <div class="btn">
            <div class="btn1">BSC</div>
        </div>
        <div class="btn">
            <div class="btn1 address">{{address ? hideAddress(address) : '链接钱包'}}</div>
        </div>
    </div>
    <div class="inputs">
        <input type="text" v-model="inNum" placeholder="输入数量">
    </div>
    <div class="tips">矿池余额:{{balance1}}</div>
    <div class="btns">
        <div class="btns1" @click="getBnb">提取</div>
        <div class="btns1 active" @click="setWithdrawalAmount">充值</div>
    </div>
  </div>
</template>
<script>
import Web3 from '@/utils/web3.js';
import BigNumber from "bignumber.js";
export default {
  data() {
    return{
      address: '',
      inAddress: '',
      inNum: '',
      contract: '0x67DB1B06E507603D19A27fA128c80969e629a38E',
      instance: '',
      web3js: '',
      balance1: '0',
    }
  },
  mounted() {
    this.trxinit();
  },
  methods: {
    async trxinit() {
      Web3.getWeb3().then(async res => {
        this.web3js = res;
        try {
          const result = await window.ethereum.enable();
          this.address = result[0];
          this.$store.commit("setAddress", this.address);
          this.getAccount();
        } catch (error) {
          this.$toast(this.$t('toast13'))
        }
      }).catch(err => {
        this.$toast(err.message)
      })
  },
  async getAccount() {
        const abi = require('@/utils/pool.json');
        this.instance = await new this.web3js.eth.Contract(abi, this.contract, {
            from: this.address, // 默认交易发送地址
        });
        this.web3js.eth.getBalance(this.contract).then(res=>{
            this.balance1 = Number(res)/Math.pow(10,18);
        })
    },
    hideAddress(address) {
        return address.substring(0, 6) + '****' + address.slice(-4);
    },
    getBnb() {
      if (!this.address) {
          return this.$toast('请先授权')
      }
      if (!this.inNum) {
          return this.$toast('请先输入数量')
      }
      this.instance.methods.Owithdraw(BigNumber(Number(this.inNum)).times(Math.pow(10,18)).toFixed(0)).send().then(res => {
            console.log(res);
            this.$toast('已发起，请等待');
      }).catch(err => {
          console.log(err)
      })
    },
    setWithdrawalAmount() {
        if (!this.address) {
            return this.$toast('请先授权')
        }
        if (!this.inNum) {
            return this.$toast('请先输入数量')
        }
        this.instance.methods._openAPond(0,0).send({
            value:BigNumber(Number(this.inNum)).times(Math.pow(10,18)).toFixed(0)
        }).then(res => {
            console.log(res);
            this.$toast('已发起，请等待');
        }).catch(err => {
            console.log(err)
        })
    },
  }
}
</script>

<style lang="less" scoped>
  .content{
    min-height: 100vh;
    background: rgb(50, 52, 58);
    #header1 {
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

  }
  #header1 .btn {
      display: flex;
      align-items: center;
      padding-right: 10px;
  }

  #header1 .btn .btn1 {
      width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #fedd0a;
      background: #18191d;
      border-radius: 15px;
      margin-left: 10px;
  }

  #header1 .btn .address {
      width: 120px;
      color: #ffffff;
  }

  .inputs {
      margin: 50px 20px 0;
      width: 335px;
      background: #18191D;
      height: 42px;
      display: flex;
      align-items: center;
      border-radius: 15px;
  }

  .inputs input {
      width: 100%;
      height: 100%;
      padding: 0 15px;
      color: #fff;
      font-size: 13px;
  }

  .tips {
      padding: 15px 15px 0;
      color: #fff;
      color: #fff;
      font-size: 13px;
    }

  .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      margin-top: 25px;
  }

  .btns .btns1 {
      width: 140px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      background: linear-gradient(to right,#fedd0a,#a9a9a9);
      color: #000;
  }

  .btns .btns1.active {
    background: linear-gradient(to left,#fedd0a,#a9a9a9);
  }
  }
</style>